import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import { CalendarBlank } from '@phosphor-icons/react';
import range from 'lodash/range';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';

import WithHeader from '~/components/Layout/WithHeader';
import { useGetFirstAvailableDay } from '~/scheduling/api/queries/shift-slot/getFirstAvailableDay';
import { GetMyScheduleResult, useGetMySchedule } from '~/scheduling/api/queries/shift-slot/getMySchedule';
import { useGetRoles } from '~/scheduling/api/queries/staff-roles/getRoles';
import { useAlert } from '~/scheduling/components/CustomAlert';
import { useWeekInterval } from '~/scheduling/useWeekInterval';
import { compareBoolean, compareValue } from '~/scheduling/utils/compare';

import CalendarHeader, { SlotStatus } from '../CalendarHeader';
import SlotItem from '../SlotItem';

const OpenSlotsList = ({ slots, isPending }: { slots: GetMyScheduleResult['slots']; isPending: boolean }) => {
    const { weekInterval } = useWeekInterval();
    const isPastWeek = weekInterval ? weekInterval.end < DateTime.now().startOf('day') : undefined;

    if (weekInterval && !isPastWeek && !isPending && !slots.length) return <OpenSlotsEmpty />;

    return (
        <Stack p="16px" spacing="12px" sx={{ overflowY: 'auto' }}>
            {isPending
                ? range(8).map((i) => <Skeleton key={i} height="68px" sx={{ flexShrink: 0 }} />)
                : slots.map((slot) => (
                      <SlotItem key={slot.id} slot={slot} renderPending renderAdjacencies renderFlags />
                  ))}
        </Stack>
    );
};

const OpenSlotsEmpty = () => {
    const { showAlert } = useAlert();

    const { weekInterval, setWeekInterval } = useWeekInterval();
    const { data: firstAvailableDay } = useGetFirstAvailableDay({ fromDay: weekInterval?.start.toISODate() });

    return (
        <Stack justifyContent="center" alignItems="center" p="16px" spacing="24px" height="60%">
            <Box
                sx={({ palette }) => ({
                    color: palette.primary[600] as string,
                    bgcolor: palette.primary[50] as string,
                    width: '56px',
                    height: '56px',
                    p: '16px',
                    borderRadius: '50%',
                })}
            >
                <CalendarBlank size="24px" weight="fill" />
            </Box>
            <Typography variant="h6">No open shifts this week</Typography>
            <Typography variant="body2" mt="8px !important" sx={({ palette }) => ({ color: palette.grey[500] })}>
                {"Check next week's schedule for more open shifts"}
            </Typography>
            <Button
                onClick={() => {
                    if (firstAvailableDay) setWeekInterval(firstAvailableDay);
                    else showAlert('warning', 'No open shifts found in following weeks');
                }}
            >
                Go to Next Week
            </Button>
        </Stack>
    );
};

const OpenSlotsTabPanel = () => {
    const { weekDayStrs } = useWeekInterval();

    const { data: roleData, isPending: isGetRolesPending } = useGetRoles();
    const roleShiftById = roleData?.roleShiftById;

    const { data: myScheduleData, isPending: isGetMySchedulePending } = useGetMySchedule();
    const slots = myScheduleData?.slots ?? [];

    const isPending = isGetRolesPending || isGetMySchedulePending;

    const filteredSlots = useMemo(
        () =>
            roleShiftById
                ? slots
                      .filter((slot) => slot.isAvailable)
                      .sort((a, b) => {
                          const aShift = roleShiftById.get(a.roleShiftId)!;
                          const bShift = roleShiftById.get(b.roleShiftId)!;

                          return (
                              compareBoolean(!!a.flags?.length, !!b.flags?.length) ||
                              compareValue(a.shiftDay, b.shiftDay) ||
                              compareValue(aShift.index, bShift.index) ||
                              compareValue(a.locationId, b.locationId)
                          );
                      })
                : [],
        [slots]
    );

    const slotStatusesByDay = useMemo(() => {
        if (!weekDayStrs) return null;

        const map: Record<string, SlotStatus[]> = {};
        weekDayStrs.forEach((day) => (map[day] = []));
        filteredSlots.forEach((slot) => {
            // Show up to a single dot for each day. Pending overrides open.
            if (map[slot.shiftDay][0] !== 'pending') map[slot.shiftDay][0] = slot.request?.id ? 'pending' : 'open';
        });
        return map;
    }, [weekDayStrs, filteredSlots]);

    return (
        <WithHeader
            header={<CalendarHeader slotStatuses={slotStatusesByDay ? Object.values(slotStatusesByDay) : null} />}
        >
            <OpenSlotsList slots={filteredSlots} isPending={isPending} />
        </WithHeader>
    );
};

export default OpenSlotsTabPanel;
