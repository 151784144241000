import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';

import actionTypes from '~/redux/actionTypes';
import { signOut } from '~/redux/actions/session';
import '~/redux/reducers/messages';
import { ErrorProps, MessageProps } from '~/types/messages';
import { AppDispatch, ReduxStore } from '~/types/redux';

export const showAlert = (message: MessageProps) => (dispatch: AppDispatch, getState: () => ReduxStore) => {
    const { messages: messagesState } = getState();

    if (messagesState.timer) clearTimeout(messagesState.timer);
    if (!messagesState.open) {
        dispatch({
            type: actionTypes.MESSAGE_SHOW,
            payload: {
                ...message,
                timer: window.setTimeout(() => dispatch(hideAlert()), 10000),
            },
        });
    }
};

export const hideAlert = () => (dispatch: AppDispatch, getState: () => ReduxStore) => {
    const { messages: messagesState } = getState();

    if (messagesState.timer) clearTimeout(messagesState.timer);
    if (messagesState.open) dispatch({ type: actionTypes.MESSAGE_HIDE });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const throwError =
    (error: any, showAlertOnError = true, shouldCaptureException = true) =>
    (dispatch: AppDispatch) => {
        const errorCode = ((error as AxiosError).status ||
            error.response?.data?.code ||
            error.response?.status) as number;
        const errorMessage = (error.response?.data?.error || error.message) as string;

        // Log the error into the console.
        console.error(`${errorMessage}: ${error}`);

        // Set the message to display into the alert.
        const message = errorMessage
            ? ({
                  open: true,
                  message: errorMessage,
                  alertSeverity: 'error',
                  status: errorCode,
              } satisfies MessageProps)
            : null;

        // If the error is because a required Authorization (possible expired token), sign-out the user.
        if (errorCode === 401) {
            const currentPath = window.location.pathname;
            if (currentPath.includes('/login/verify') || currentPath.includes('/signup/verify')) {
                if (message && showAlertOnError) dispatch(showAlert(message));
                return;
            }

            void dispatch(signOut(currentPath));

            return;
        } else {
            // don't send 401 errors to Sentry. They are expected
            if (shouldCaptureException) Sentry.captureException(error);
        }

        if (message && showAlertOnError) dispatch(showAlert(message));
    };

export const handleError =
    (errorProps: ErrorProps, showAlertOnError = true) =>
    (dispatch) => {
        // Extract the error props.
        const { error, consoleMessage, alertMessage } = errorProps;

        Sentry.captureMessage(consoleMessage);

        // Log the error into the console.
        console.error(`${consoleMessage}: ${error}`);

        // Set the message to display into the alert.
        const message: MessageProps = {
            open: true,
            message: alertMessage || error.message,
            alertSeverity: 'error',
            status: error.response?.data?.code,
        };

        if (showAlertOnError) dispatch(showAlert(message));
    };
