import { Box, Button, Chip, Typography, styled, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { PiArrowRight, PiCheck } from 'react-icons/pi';

import { SHIFT_SLOT_REQUEST_STATUS } from '@allie/utils/src/constants/scheduling/shift-slot.constants';

import { useApproveShiftRequests } from '~/scheduling/api/queries/shift-requests/approveSlotRequests';
import { useUpdateShiftRequest } from '~/scheduling/api/queries/shift-requests/updateSlotRequest';
import { VerticalSeparator } from '~/scheduling/components/shared';
import { useWindowSize } from '~/scheduling/components/useWindowSize';

import { ShiftApproval } from '../../types';

import { CardForm } from './CardForm';

const CardFormWrapper = styled('form')(({ theme }) => ({
    height: '100%',
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: '8px',
    boxShadow: '0px 5px 8px 0px rgba(57, 57, 57, 0.12)',
    backgroundColor: theme.palette.grey[50],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'hidden',
}));

const ButtonWrapper = styled(Button)(({ theme }) => ({
    justifyContent: 'center',
    padding: '16px 14px',
    display: 'flex',
    gap: '6px',
    alignItems: 'center',
    width: '100%',
    backgroundColor: 'transparent',
    borderRadius: 'unset',

    '&:hover': {
        backgroundColor: 'transparent',
    },
    '&:active': {
        backgroundColor: theme.palette.grey[200],
    },
    '&.Mui-disabled': {
        backgroundColor: 'transparent',
        '& > *': {
            color: `${theme.palette.grey[200]} !important`,
        },
    },
}));

interface CardHeaderProps {
    openSlotsAmount: number;
    date: DateTime;
    shift: string;
    staffRole: string;
    location: string;
    isMultiSelect: boolean;
    form: UseFormReturn<ShiftApproval.FormData>;
}

const CardHeader = ({ date, shift, staffRole, location, isMultiSelect, form, openSlotsAmount }: CardHeaderProps) => {
    const { palette } = useTheme();
    const multipleRequestsIds = form.watch('multipleRequestsIds');
    const isCompleted = multipleRequestsIds.length === openSlotsAmount;

    return (
        <Box padding="16px">
            {isMultiSelect && (
                <Box
                    marginBottom="12px"
                    alignItems="center"
                    display="flex"
                    gap="4px"
                    boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.08)"
                    bgcolor={(isCompleted ? palette.primary[25] : palette.error[25]) as string}
                    border={`1px solid ${isCompleted ? palette.primary[200] : palette.error[200]}`}
                    borderRadius="6px"
                    padding="4px 8px"
                >
                    <Typography
                        flex={1}
                        variant="body1"
                        color={palette.grey[500]}
                        fontWeight={600}
                        fontSize="13px"
                        lineHeight="16px"
                    >
                        Choose {openSlotsAmount} staff for this shift.
                    </Typography>
                    <Box
                        flexShrink={0}
                        padding="2px 6px"
                        bgcolor={(isCompleted ? palette.primary[600] : palette.error[600]) as string}
                        borderRadius="4px"
                    >
                        <Typography variant="body1" fontWeight={400} fontSize="13px" color="white" lineHeight="16px">
                            <span style={{ fontSize: '15px', fontWeight: 700, lineHeight: '20px' }}>
                                {multipleRequestsIds.length}
                            </span>
                            /{openSlotsAmount}
                        </Typography>
                    </Box>
                </Box>
            )}
            <Box display="flex" gap="16px">
                <Box>
                    <Typography
                        textAlign="center"
                        variant="body1"
                        fontWeight={700}
                        color={palette.grey[900]}
                        fontSize="24px"
                        lineHeight="28px"
                    >
                        {date.toFormat('dd')}
                    </Typography>
                    <Typography textAlign="center" marginTop="2px" variant="body1" color={palette.grey[600]}>
                        {date.toFormat('ccc')}
                    </Typography>
                </Box>
                <VerticalSeparator sx={{ backgroundColor: palette.grey[100] }} />
                <Box>
                    <Typography variant="body1" fontWeight={700} color={palette.grey[900]} fontSize="15px">
                        {date.toFormat('MMMM, yyyy')}
                    </Typography>
                    <Box marginTop="4px" display="flex" gap="4px">
                        <Chip size="small" label={shift} />
                        <Chip size="small" label={staffRole} />
                        <Chip size="small" label={location} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

interface CardButtonSectionProps {
    form: UseFormReturn<ShiftApproval.FormData>;
    isLoading: boolean;
    openSlotsAmount: number;
    isMultiSelect?: boolean;
    onSkip?: VoidFunction;
}

const CardButtonSection = (props: CardButtonSectionProps) => {
    const { palette } = useTheme();
    const [singleRequestId, multipleRequestIds] = props.form.watch(['singleRequestId', 'multipleRequestsIds']);

    // We can't use the `isDirty` state from react-form-hook because it uses the default value
    // as source of truth, checking if the current form state is different from it. When there
    // is only one staff, the `isDirty` will be false when the staff is deselected - so we need
    // to use JS to determine if the button should be enabled.
    const isSubmitEnabled = props.isMultiSelect
        ? multipleRequestIds.length > 0 && multipleRequestIds.length <= props.openSlotsAmount
        : singleRequestId !== null;

    return (
        <Box display="flex" width="100%">
            {props.onSkip && (
                <ButtonWrapper
                    type="button"
                    sx={{ borderRight: `1px solid ${palette.grey[200]}` }}
                    onClick={props.onSkip}
                    disabled={props.isLoading}
                >
                    <PiArrowRight size={20} color={palette.grey[500]} />
                    <Typography variant="body1" fontSize="16px" fontWeight={700} color={palette.grey[500]}>
                        Skip
                    </Typography>
                </ButtonWrapper>
            )}
            <ButtonWrapper type="submit" disabled={!isSubmitEnabled || props.isLoading}>
                <PiCheck size={20} color={palette.primary[500] as string} />
                <Typography variant="body1" fontSize="16px" fontWeight={700} color={palette.primary[500] as string}>
                    Approve
                </Typography>
            </ButtonWrapper>
        </Box>
    );
};

interface CardProps extends ShiftApproval.CardProps {
    onResolve: VoidFunction;
    onSkip: VoidFunction;
}

export const Card = (props: CardProps) => {
    const [width] = useWindowSize();
    const windowWidth = (width || window.innerWidth) - 32 + 'px';

    const { mutateAsync: updateShiftRequest, isPending: isLoading } = useUpdateShiftRequest();
    const { mutateAsync: approveShiftRequests } = useApproveShiftRequests();

    const isMultiSelect = props.requests.length > 1;
    const hasOneStaff = props.requests.length === 1;

    const form = useForm<ShiftApproval.FormData>({
        defaultValues: { singleRequestId: hasOneStaff ? props.requests[0].id : null, multipleRequestsIds: [] },
    });

    const onSubmit = async (data: ShiftApproval.FormData) => {
        if (isMultiSelect) {
            await approveShiftRequests({ requests: data.multipleRequestsIds });
        } else {
            await updateShiftRequest({
                requestId: data.singleRequestId!,
                data: { status: SHIFT_SLOT_REQUEST_STATUS.APPROVED },
            });
        }
        props.onResolve();
    };

    return (
        <CardFormWrapper onSubmit={form.handleSubmit(onSubmit)} style={{ width: windowWidth }}>
            <CardHeader
                shift={props.shiftName}
                location={props.locationName}
                staffRole={props.staffRoleName}
                openSlotsAmount={props.openSlotsAmount}
                date={props.shiftDay}
                isMultiSelect={isMultiSelect}
                form={form}
            />
            <CardForm multiSelect={isMultiSelect} requests={props.requests} form={form} />
            <CardButtonSection
                isMultiSelect={isMultiSelect}
                onSkip={hasOneStaff ? props.onSkip : undefined}
                openSlotsAmount={props.openSlotsAmount}
                form={form}
                isLoading={isLoading}
            />
        </CardFormWrapper>
    );
};
